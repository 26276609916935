import React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../components/seo'

class CustomSolutions extends React.Component {
  render() {
    const { data } = this.props
    return (
      <Layout>
        <SEO
          title='Custom Web Development Solutions'
          description='Custom designed web solutions. From Progressive Web Apps to API solutions, we can build any website.'
          keywords={[
            'website',
            'wordpress',
            'joomla',
            'web design',
            'progressive web apps',
          ]}
        />

        <div id='main' className='alt'>
          <section id='one'>
            <div className='inner'>
              <header className='major'>
                <h1>Custom Web Development</h1>
              </header>
              <span className='image main'>
                <Img
                  fluid={data.customSolutionsPic.childImageSharp.fluid}
                  alt='Black Alsatian website on various device screens'
                />
              </span>
              <p>We cater for all clients.</p>
              <p>
                Simple, static one page HTML website? We’ll build it for you.
              </p>
              <p>
                WordPress or Joomla? Great, we’ll do that too. We’ll build you a
                custom WordPress theme if you want. E-Commerce? No problem at
                all.
              </p>
              <p>
                We go above and beyond the regular web design solutions that
                everyone else does. We stay up to date with the latest in web
                design technologies, and can develop just about any web solution
                you can think of.
              </p>
              <p>
                From API development to Progressive Web Apps - you name it, we
                got you.
              </p>
              <p>
                Contact us today and let's have a chat about your website needs.
              </p>
              <ul className='actions'>
                <li>
                  <a
                    href='#contact'
                    className='button special'
                    aria-label='Get started'
                  >
                    Get Started
                  </a>
                </li>
              </ul>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default CustomSolutions

export const query = graphql`
  query {
    customSolutionsPic: file(relativePath: { eq: "blackals-all-devices.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1380) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
